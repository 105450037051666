import React from 'react';
import styled, { css } from 'styled-components';

import { convertThumbnailToPictureProps } from '@tager/web-modules';

import SellCar from '@/assets/svg/detailed-car/sell-car.svg';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import { ButtonNextLink } from '@/components/Button';
import Picture from '@/components/Picture';

import { DetailedCarSellBannerProps } from './DetailedCarSellBanner.types';

function DetailedCarSellBanner({
  image,
  title,
  link,
  buttonTitle,
  description,
}: DetailedCarSellBannerProps) {
  return (
    <Component>
      <Left>
        <ImageWrapper>
          {!image ? (
            <SellCar />
          ) : (
            <Image
              loading="lazy"
              {...convertThumbnailToPictureProps(image)}
              alt={title}
            />
          )}
        </ImageWrapper>

        <Content>
          <Title>{title}</Title>
          {description && <Description>{description}</Description>}
        </Content>
      </Left>

      <Right>
        <ButtonNextLink to={link} variant="contained">
          {buttonTitle}
        </ButtonNextLink>
      </Right>
    </Component>
  );
}

export default DetailedCarSellBanner;

const Component = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 40px;
  border-radius: 10px;
  background: ${(props) => props.theme.gray_1};

  ${media.mobile(css`
    padding: 20px 10px;
    flex-direction: column;
    align-items: flex-start;
  `)}
`;

const Left = styled.div`
  flex: 1;
  display: flex;
  align-items: center;

  ${media.mobile(css`
    width: 100%;
    align-items: flex-start;
    flex-direction: column;
  `)}
`;

const ImageWrapper = styled.div`
  flex: 0 0 100px;
  max-width: 100px;
  height: 100px;

  ${media.mobile(css`
    flex: auto;
    max-width: none;
    width: 100%;
    display: flex;
    justify-content: center;
  `)}

  svg {
    width: 100%;
    height: 100%;

    path {
      fill: ${colors.main};
    }

    ${media.mobile(css`
      flex: 0 0 100px;
      max-width: 100px;
      height: 100px;
    `)}
  }
`;

const Image = styled(Picture)`
  width: 100%;
  height: 100%;

  ${media.mobile(css`
    flex: 0 0 100px;
    max-width: 100px;
    height: 100px;
  `)}

  picture,
  img {
    width: 100%;
    height: 100%;
  }

  img {
    object-fit: cover;
  }
`;

const Content = styled.div`
  margin-left: 40px;

  ${media.mobile(
    css`
      margin: 30px 0 0;
    `
  )}
`;

const Title = styled.p`
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: ${(props) => props.theme.black};
`;

const Description = styled.p`
  font-size: 14px;
  line-height: 16px;
  margin-top: 5px;
  color: ${(props) => props.theme.blue100};
`;

const Right = styled.div`
  display: flex;
  width: 100%;
  max-width: 289px;
  margin-left: 20px;

  ${media.mobile(css`
    max-width: none;
    margin: 30px 0 0;
  `)}
`;
